<template>
  <div>
    <el-card class="box-card">
      <div style="display:flex">
        <h2>基本信息</h2>
      </div>
      <div style="display: flex;">
        <div style="margin: 0 0 0 20px">
          <el-avatar
            :src="userInfo.avatar" shop="circle"
            :size="100"></el-avatar>
        </div>
        <div>
          <div style="margin: 0 0 0 40px ">
            <h3>{{ userInfo.nickname }}</h3>
            <el-button type="primary" round size="mini" @click="jumpToConsole">跳转后台</el-button>
          </div>
        </div>
      </div>
      <div style="width:100%;background-color:#FFFAFAFA;display: flex;justify-content:space-around;margin-top:10px">
        <div>
          <span>登录时间：</span>
          <span>{{ userInfo.logintime | formatData }}</span>
        </div>
        <div>
          <span>最后登录：</span>
          <span>{{ userInfo.prevtime | formatData }}</span>
        </div>
      </div>
    </el-card>
    <el-card class="box-card" style="margin-top:20px">
      <el-tabs type="border-card">
        <el-tab-pane label="个人信息" class="info">
          <div style="display: flex;flex-direction:column">
            <div style="width: 500px;display: flex;justify-content:space-around;margin: 5px">
              <span style="font-weight:900;">用户名称</span>
              <span>{{ userInfo.nickname }}</span>
              <div>
                <el-button size="mini">修&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;改</el-button>
              </div>
            </div>
            <div style="width: 500px;display: flex;justify-content:space-around;margin: 5px">
              <span style="font-weight:900;">用户编号</span>
              <span>{{ userInfo.id }}</span>
              <div style="width: 90px">

              </div>
            </div>
            <div style="width: 500px;display: flex;justify-content:space-around;margin: 5px">
              <span style="font-weight:900;width:100px">登录密码</span>
              <span>**********</span>
              <div>
                <el-button size="mini" @click="openDialog">修&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;改</el-button>
              </div>
            </div>
            <div style="width: 500px;display: flex;justify-content:space-around;margin: 5px">
              <span style="font-weight:900;">交易密码</span>
              <span>未设置</span>
              <div>
                <el-button size="mini">立即设置</el-button>
              </div>
            </div>
            <div style="width: 500px;display: flex;justify-content:space-around;margin: 5px">
              <span style="font-weight:900;">实名验证</span>
              <span>未验证</span>
              <div>
                <el-button size="mini">立即验证</el-button>
              </div>
            </div>
            <div style="width: 500px;display: flex;justify-content:space-around;margin: 5px">
              <span style="font-weight:900;">绑定邮箱</span>
              <span>未绑定</span>
              <div>
                <el-button size="mini">立即绑定</el-button>
              </div>
            </div>
            <div style="width: 500px;display: flex;justify-content:space-around;margin: 5px">
              <span style="font-weight:900;">绑定手机</span>
              <span>{{ userInfo.mobile }}</span>
              <div>
                <el-button size="mini">修&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;改</el-button>
              </div>
            </div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="关联账号">关联账号</el-tab-pane>
      </el-tabs>
    </el-card>
    <div>
      <el-dialog title="修改密码" :visible.sync="dialogFormVisible" width="30%">
        <el-form :model="form" style="width:300px" :rules="rules" ref="ruleForm">
          <el-form-item label="原始密码" prop="oldPassword">
            <el-input v-model="form.oldPassword" autocomplete="off" style="width:200px" type="password"
                      show-password></el-input>
          </el-form-item>
          <el-form-item label="新 密 码" prop="newPassword">
            <el-input v-model="form.newPassword" autocomplete="off" style="width:200px" type="password"
                      show-password></el-input>
          </el-form-item>
          <el-form-item label="确认密码" prop="confirmPassword">
            <el-input v-model="form.confirmPassword" autocomplete="off" style="width:200px" type="password"
                      show-password></el-input>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="dialogFormVisible = false">取 消</el-button>
          <el-button type="primary" @click="updatePassword('ruleForm')">确 定</el-button>
        </div>
      </el-dialog>
    </div>

  </div>
</template>

<script>
import moment from "moment";

export default {
  name: "info",
  data() {
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请输入密码"));
      } else {
        if (this.form.newPassword !== "") {
          this.$refs.ruleForm.validateField("validatePass2");
        }
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.form.newPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };
    return {
      userInfo: {},
      form: {},
      dialogFormVisible: false,
      rules: {
        oldPassword: [
          { required: true, message: "请输入密码", trigger: "blur" },
          { min: 6, max: 10, message: "长度在 6 到 10 个字符", trigger: "blur" }
        ],
        newPassword: [
          { required: true, message: "请输入新密码", trigger: "blur" },
          { validator: validatePass, trigger: "blur" }
        ],
        confirmPassword: [
          { required: true, message: "请输入确认密码", trigger: "blur" },
          { validator: validatePass2, trigger: "blur" }
        ]
      }
    };
  },
  created() {
    let userContent = localStorage.getItem("user");
    this.userInfo = JSON.parse(userContent);
  },
  methods: {
    jumpToConsole() {
      window.open(this.userInfo.console);
    },
    openDialog() {
      this.dialogFormVisible = true;
    },
    updatePassword(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.$axios.post("https://index.myhemall.com:12120/api/user/changePassword", {
            oldPassword: this.form.oldPassword,
            newPassword: this.form.newPassword,
            confirmPassword: this.form.confirmPassword,
            username: this.userInfo.username
          }).then((res) => {
            if (res.data.errno == 0) {
              this.$message.success(res.data.data);
              this.dialogFormVisible = false;
            } else {
              this.$message.error(res.data.errmsg);
            }
          });
        } else {

          return false;
        }
      });
      if (this.form.newPassword != this.from.confirmPassword) {
        this.$message.error("两次密码不一样");
      }
    }
  },
  filters: {
    formatData(value) {
      return moment(value).format("YYYY-MM-DD HH:mm");
    }
  }
};
</script>

<style scoped>
.info span {
  width: 100px
}
</style>
